export const challengesAlternatives = [
  "Bli bedre i studieteknikk",
  "Bli klar til avsluttende prøve",
  "Bli klar til eksamen",
  "Bli mer selvstendig",
  "Eksamensforberedelser",
  "Forstå det læreren forklarer på skolen",
  "Få bedre forståelse i faget",
  "Få bedre karakter",
  "Få oversikt over pensum",
  "Få økt konsentrasjon",
  "Gjennomgang av pensum",
  "Gjøre det lettere å jobbe med skole",
  "Gjøre skole til noe positivt",
  "Henge med på tempoet i klassen",
  "Oppleve mestringsfølelse i faget",
  "Se meningen med det som undervises på skolen",
  "Senke presset rundt skolearbeid",
  "Vise kompetanse på skolen",
  "Vise kunnskapen sin til læreren",
];

export const placeholderTexts = {
  goalLater: "Eksempel på gode mål: \nØke motivasjon og mestring, forbedre karaktersnitt, bli trygg på muntlige presentasjoner og tette alle hull fra tidligere år\nTips: Ikke sett for ambisiøse mål. Legg opp noe dere får til først, og så kan du øke målene etterhvert.",
  goalToday: "Eksempel på god tekst på \"Hva har X og jeg jobbet med i dag: *\n" +
    "Hovedfokus for timen i dag har vært på forberedelser for prøver, men vi har også jobbet noe med temaet fra sist og hatt en motivasjons-prat for prøvene som kommer. \n" +
    "1. Fikk en oppdatering på hva som skjer på skolen utenom det jeg vet fra lekseplanen\n" +
    "2. Jobbet med 2 oppgaver som X ikke hadde greid fra sist. Ellers alt bra!\n" +
    "3. Jobbet videre med regelbok i norsk for sakprosa. \n" +
    "4. Snakket om hva han må jobbe med før prøvene som kommer, og vi satt opp en leseplan han kan ta utgangspunkt i. \n" +
    "5. Motivasjons-prat   Snakket om hvorfor det er viktig å stå på nå, og hvor ekstra deilig det blir å ta en velfortjent runde Fortnite etter siste prøve på tirsdag!",
  nextTime: "Eksempel på god tekst på dette: \n" +
    "Det er mye på skolen nå, men vi har lagt opp en god plan og oppgavene for denne uken blir for X å gjøre det vi har satt på leseplanen. Han har lovet at han ikke skal game noe før kl.20 hver kveld, så fra 16-20 hver dag er dedikert lesetid. Hver kveld skal han sende meg en kort rapport på SMS med hvilke oppgaver han har gjort, hva som var vanskelig og hva som gikk fint. Innen torsdag bør han ha skrevet øve-stilen jeg har gitt ham, så får jeg rettet den til vi møtes igjen på fredag. \n" +
    "Om X følger planen kommer dette til å gå veldig bra -lykke til :)",
  notes: "Eksempel på gode notater: \n" +
    "X hadde gjort alle oppgavene fra sist -veldig bra! Han synes det er litt mye på skolen nå, de har tre prøver den neste uken. Jeg anbefalte å stå over fotballtrening torsdag for å øve til matteprøve. \n" +
    "Vi startet med norsk skriveøkt. Regelboken hans begynner å bli veldig bra og vi startet der vi slapp sist, med sjangertrekk ved sakprosa. \n" +
    "Etter å ha fikset mer i regelboken, ga vi planen for neste uke. For at han skal gjøre det bra på prøvene må han være strukturert og passe på å ikke miste konsentrasjonen, så jeg ga ham en liten “pep talk” på det. ",
  motivationText: "Eksempel: Eleven var veldig ivrig med å løse oppgavene og viste tegn til glede ved å mestre matte, men var også utålmodig med å bli ferdig med leksene.",
};
