











import ReportList from "@/components/reports/views/ReportList.vue";
import SellerReports from "@/components/reports/views/SellerReports.vue";
import { userModule } from "@/store/modules/user";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: { ReportList, SellerReports },
  name: "Reports",
  setup() {
    return {
      isSeller: userModule.state.user.seller
    };
  }
});
