












































import { challengesAlternatives } from "@/helpers/reportFormUtils";
import { computed, defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import Vue from "vue";
import { MainGoals } from "@/interfaces/Report";

export default defineComponent({
  name: "MainGoals",
  props: {
    value: {
      type: Object as PropType<MainGoals>,
      required: true,
    },
    submitted: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },
  setup(props, { emit, refs }) {
    const _value = ref(props.value);

    watchEffect(() => {
      emit("input", _value);
    });

    watchEffect(() => {
      if (props.submitted === true) {
        (refs.form as Vue & { validate: () => boolean }).validate();
      }
    });

    const validForm = ref(false);
    watchEffect(() => {
      if (validForm) emit("valid");
    });

    const formRules = {
      valid: (v: string) => !!v || "Du må velge ett av alternativene"
    };

    const filteredChallengesAlternatives1 = computed(() => challengesAlternatives.filter(o => o !== _value.value.longTermGoal2 && o !== _value.value.longTermGoal3));
    const filteredChallengesAlternatives2 = computed(() => challengesAlternatives.filter(o => o !== _value.value.longTermGoal1 && o !== _value.value.longTermGoal3));
    const filteredChallengesAlternatives3 = computed(() => challengesAlternatives.filter(o => o !== _value.value.longTermGoal1 && o !== _value.value.longTermGoal2));

    return {
      _value,
      filteredChallengesAlternatives1,
      filteredChallengesAlternatives2,
      filteredChallengesAlternatives3,
      formRules,
      validForm,
    };
  },
});
