import Report, { FirstReport, PeriodicalReport, RegularReport, ReportType, ReportResObject, RetrieveReportParams } from "@/interfaces/Report";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/reports";

export const createFirstReport = async(firstReportForm: FirstReport): Promise<Report> => {
  const res = await learnlinkAPI.axios.post<Report>(`${ENDPOINT}/first`, firstReportForm);
  return res.data;
};

export const createPeriodicalReport = async(periodicalReportForm: PeriodicalReport): Promise<Report> => {
  const res = await learnlinkAPI.axios.post<Report>(`${ENDPOINT}/periodical`, periodicalReportForm);
  return res.data;
};

export const createReport = async(reportForm: RegularReport): Promise<Report> => {
  const res = await learnlinkAPI.axios.post<Report>(`${ENDPOINT}/`, reportForm);
  return res.data;
};

export const getAllReportsForUser = async(userUID: string, isSeller: boolean): Promise<RetrieveReportParams[]> => {
  const subPath = isSeller ? "seller" : "customer";
  const res = await learnlinkAPI.axios.get<RetrieveReportParams[]>(`${ENDPOINT}/${subPath}/${userUID}`);
  return res.data;
};

export const getAllReportsForProjectByType = async(reportType: ReportType, projectID: string): Promise<Report[]> => {
  const res = await learnlinkAPI.axios.get<Report[]>(`${ENDPOINT}/byType/${reportType}/${projectID}`);
  return res.data;
};

export const getPreviousReport = async(projectID: string): Promise<ReportResObject> => {
  const res = await learnlinkAPI.axios.get<ReportResObject>(`${ENDPOINT}/mountExisting/${projectID}`);
  return res.data;
};
