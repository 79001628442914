
















































































































































































































































import { createPeriodicalReport, getAllReportsForProjectByType } from "@/api/reportsService";
import MainGoals from "@/components/reports/forms/MainGoalsForm.vue";
import SixWeekPlan from "@/components/reports/forms/WeeksPlanForm.vue";
import handleError from "@/helpers/errors";
import { useNotifier } from "@/providers/notifier";
import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import Lesson from "@/interfaces/Lesson";
import Vue from "vue";

export default defineComponent({
  name: "PeriodicalReportForm",
  components: {
    MainGoals,
    SixWeekPlan,
  },
  props: {
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    studentName: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, {
    emit,
    refs,
  }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const vm = reactive({
      loading: false,
      goals: {
        longTermGoal1: "",
        longTermGoal2: "",
        longTermGoal3: "",
      },
      mainGoalsValid: false,
      previousWeeks: {
        previousWeek1: "",
        previousWeek2: "",
        previousWeek3: "",
        previousWeek4: "",
        previousWeek5: "",
        previousWeek6: "",
      },
      reportForm: {
        customerUID: props.lesson.customerUID,
        improvementPlan: "",
        lessonID: props.lesson.ID,
        name: props.studentName,
        positiveTopics: "",
        previousImprovementPlan: "",
        projectID: props.lesson.projectID,
        sellerProgressImprovements: [],
        sellerProgressRating: 0,
        sellerUID: props.lesson.sellerUID || "",
      },
      sixWeeksValid: false,
      submitted: false,
      weeks: {
        week1: "",
        week2: "",
        week3: "",
        week4: "",
        week5: "",
        week6: "",
      },
    });

    async function getPreviousPeriodicalReport() {
      const periodicalReports = await getAllReportsForProjectByType("periodicalReport", props.lesson.projectID);
      const previousReport = periodicalReports[0];
      if (previousReport) {
        vm.previousWeeks.previousWeek1 = previousReport.week1;
        vm.previousWeeks.previousWeek2 = previousReport.week2;
        vm.previousWeeks.previousWeek3 = previousReport.week3;
        vm.previousWeeks.previousWeek4 = previousReport.week4;
        vm.previousWeeks.previousWeek5 = previousReport.week5;
        vm.previousWeeks.previousWeek6 = previousReport.week6;
        vm.reportForm.previousImprovementPlan = previousReport.improvementPlan;
        vm.goals.longTermGoal1 = previousReport.longTermGoal1;
        vm.goals.longTermGoal2 = previousReport.longTermGoal2;
        vm.goals.longTermGoal3 = previousReport.longTermGoal3;
      }
    }

    onMounted(async() => await getPreviousPeriodicalReport());
    const validForm = ref(false);

    function closeForm() {
      _value.value = false;
      emit("success");
    }

    const notify = useNotifier();
    const formRules = {
      required: (v: string) => !!v || "Obligatorisk felt",
    };
    const submitReport = async() => {
      vm.loading = true;
      vm.submitted = true;
      (refs.form as Vue & { validate: () => boolean; }).validate();
      if (validForm.value && vm.sixWeeksValid && vm.mainGoalsValid && vm.reportForm.sellerProgressRating > 0) {
        if (vm.reportForm.sellerProgressRating < 3 && !vm.reportForm.sellerProgressImprovements.length) {
          notify({
            title: "Husk egenvurderingen av opplegget ditt",
            message: "Det er vikti at vi vet hvordan det går, og hva vi kan hjelpe med dersom det ikke går tipp-topp.",
            type: "error",
          });
        }
        else {
          try {
            await createPeriodicalReport({
              ...vm.reportForm,
              ...vm.weeks,
              ...vm.goals,
              ...vm.previousWeeks,
            });
            emit("submitted");
            closeForm();
          }
          catch (e) {
            notify({
              title: "Klarte ikke sende Rapporten 🤯",
              message: "Kontakt support hvis problemet vedvarer.",
              type: "error",
            });
            handleError(e);
          }
        }
      }
      else {
        notify({
          title: "Rapporten er mangelfull 📋",
          message: "Fyll ut alle feltene nøye! Det er viktig for å gi god innsikt i undervisningen. 😃",
          type: "error",
        });
      }
      vm.loading = false;
    };
    return {
      _value,
      closeForm,
      formRules,
      submitReport,
      validForm,
      vm,
    };
  },
});
