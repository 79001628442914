














































import { RetrieveReportParams } from "@/interfaces/Report";
import Moment from "@/services/moment";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "RegularReportView",
  props: {
    report: {
      type: Object as PropType<RetrieveReportParams>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const closeForm = () => emit("close");
    const readableDate = computed(() => `${Moment.unix(props.report.lesson?.startTime || 0).format("LLLL")} – ${Moment.unix(props.report.lesson?.endTime || 0).format("HH:mm")}`);

    return {
      closeForm,
      readableDate,
    };
  },
});
