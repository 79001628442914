



































import { rateLesson } from "@/api/lessonService";
import Lesson from "@/interfaces/Lesson";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "RateLesson",
  props: {
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
  },
  setup(props) {
    const submitRating = async(rating: number) => {
      // TODO: Intercom
      // if (rating === 1) {
      //   window.Intercom("showNewMessage", "Jeg opplevde problemer under timen min " + Moment.unix(props.lesson.startTime).format("DD.MM.YYYY HH:mm") + ":");
      // }
      await rateLesson(props.lesson.ID, rating);
    };
    return {
      submitRating,
    };
  },
});
