



























































































import FirstReportView from "@/components/reports/views/FirstReportView.vue";
import PeriodicalReportView from "@/components/reports/views/PeriodicalReportView.vue";
import RegularReportView from "@/components/reports/views/RegularReportView.vue";
import { RetrieveReportParams } from "@/interfaces/Report";
import Moment from "@/services/moment";
import { defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import useAsyncData from "@/hooks/useAsyncData";
import { getAllReportsForUser } from "@/api/reportsService";

export default defineComponent({
  name: "ReportList",
  components: {
    RegularReportView,
    FirstReportView,
    PeriodicalReportView,
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup: (props, { emit }) => {
    const { isLoading, data: reports, refresh } = useAsyncData(
      () => getAllReportsForUser(userModule.state.userId, userModule.state.user.seller),
    );
    const _value = ref(props.value);
    watchEffect(() => {
      refresh();
      _value.value = false;
      emit("input", _value);
    });

    const vm = reactive({
      reportViewVisible: false,
      report: {} as RetrieveReportParams,
    });

    const seller = userModule.state.user.seller;
    const readableDate = (report: RetrieveReportParams): string => `${Moment.unix(report.lesson?.startTime || 0).format("LLLL")} – ${Moment.unix(report.lesson?.endTime || 0).format("HH:mm")}`;
    const readableReportCreationDate = (report: RetrieveReportParams): string => Moment.unix(report.created).format("LLLL");
    const name = (report: RetrieveReportParams): string => seller ? report.name : report.seller ? report.seller.profile.firstName : "";
    const getReportType = (report: RetrieveReportParams): string => report.reportType === "firstReport"
      ? "Kartleggingsrapport"
      : report.reportType === "periodicalReport"
        ? "Perioderapport"
        : "Rapport";
    const openReport = (report: RetrieveReportParams): void => {
      vm.report = report;
      vm.reportViewVisible = true;
    };
    return {
      isLoading,
      name,
      openReport,
      readableDate,
      readableReportCreationDate,
      reports,
      getReportType,
      seller,
      vm,
    };
  },
});
