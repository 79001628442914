import Lesson, { CreateLesson, UpdateLessonParams } from "@/interfaces/Lesson";
import { UserType } from "@/interfaces/UserType";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/lessons";

export const createLesson = async(lesson: CreateLesson, repeat: number): Promise<Lesson[]> => {
  const lessonRes = await learnlinkAPI.axios.post<Lesson[]>(
    `${ENDPOINT}/${repeat}`, lesson
  );
  return lessonRes.data;
};

export const getFirstLesson = async(projectID: string): Promise<Lesson> => {
  const res = await learnlinkAPI.axios.get<Lesson>(`${ENDPOINT}/firstLesson/${projectID}`);
  return res.data;
};

export const getLessons = async(userType: UserType, userId: string): Promise<Lesson[]> => {
  const lessonRes = await learnlinkAPI.axios.get<Lesson[]>(
    `${ENDPOINT}/${userType}/${userId}`
  );
  return lessonRes.data;
};

export const getLesson = async(lessonID: string): Promise<Lesson> => {
  const lessonRes = await learnlinkAPI.axios.get<Lesson>(
    `lessons/${lessonID}`
  );
  return lessonRes.data;
};

export const getLessonsWithoutReport = async(sellerUID: string): Promise<Lesson[]> => {
  const res = await learnlinkAPI.axios.get<Lesson[]>(`${ENDPOINT}/pendingReports/${sellerUID}`);
  return res.data;
};

export const cancelLesson = async(lessonID: string): Promise<Lesson> => {
  const lessonRes = await learnlinkAPI.axios.put<Lesson>(
    `${ENDPOINT}/cancel/${lessonID}`
  );
  return lessonRes.data;
};

export const updateLesson = async(lesson: UpdateLessonParams): Promise<Lesson> => {
  const lessonRes = await learnlinkAPI.axios.put<Lesson>(
    `${ENDPOINT}/${lesson.ID}`,
    lesson
  );
  return lessonRes.data;
};

export const rateLesson = async(lessonID: string, rating: number): Promise<void> => {
  const res = await learnlinkAPI.axios.put(`/lessons/rate/${lessonID}`, {
    rating: rating
  });
  return res.data;
};
